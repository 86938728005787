const initFormScroll = () => {
    $(window).on('ajaxForm:success', () => {
        const $errors = $('.errorlist');
        if ($errors.length) {
            const firstError = $($errors[0]);
            const scrollTo = firstError.offset().top - 180;
            $('html, body').animate({ scrollTop: scrollTo }, 700);
        }
    }).on('ajaxForm:request-sent', (event, $form) => {
        $form.find('.btn').css('pointer-events', 'none');
    });
};

$(window).on('softpage:opened', () => {
    $(window).trigger('initAjaxForm');
    initFormScroll();
});

$(document).ready(() => {
    initFormScroll();
});
